<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="2">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="名称">
              <a-input v-model="searchData.name" placeholder="名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
           <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="部门">
                  <a-tree-select
                    v-model="searchData.deptId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    treeDefaultExpandAll
                    @change="handleChangeDept"
                    placeholder="部门"
                    allowClear
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('add')">新增</a-button>
        <a-button type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button type="primary" @click="toHandler('del')">删除</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
               :columns="columns" :data-source="tableData" bordered :pagination="page"
               @change="changeTable" :customRow="changeTableRow">

                 <span slot="flagEnable" slot-scope="text,record">
                    <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)"/>
                </span>
      </a-table>
      <!-- 编辑表单 -->
      <SysStation-edit-modal ref="SysStationEditModal" @reload="getData"></SysStation-edit-modal>
    </div>
  </a-card>
</template>

<script>
import {columns} from './components/colums.js'
import SysStationEditModal from './components/SysStationEditModal.vue'
import {delSysStation, listSysStation, selectByIdSysStation} from './api/SysStationApi'

// const pageSource = {
//   current: 1,
//   pageSize: 10,
//   total: 0
// }
export default {
  name: 'sysStation',
  components: {
    SysStationEditModal
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },

      selectedRowKeys: [],
      selectedRows: [],
      depts:[]
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    }
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true

      listSysStation({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      }).finally(() => this.tableLoading = false)
    },
      getDeptList() {
      this.axios.get('/api/base/system/department/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
      // if (extra.selected && extra.triggerNode.$children.length > 0) {
      //   this.$message.error('不能选择父节点 ' + extra.triggerNode.title)
      //   value.splice(
      //     value.findIndex((item) => item === extra.triggerValue),
      //     1
      //   )
      // }
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
       this.page.current = 1;
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page.current =1
      this.page.pageSize = 10
      this.getData()
    },
    //修改数据
    onChangeStatus(record) {
      let status = record.flagEnable
      this.axios.get(`/api/base/system/station/status/isEnable?stationId=${record.id}&flagEnable=${status}`).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.getList()
        } else {
          this.getList()
        }
      }).catch(err => {
        this.getList()
      })
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.SysStationEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.product({
          message: '请选择一条记录'
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.SysStationEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delSysStation(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({message: res.message})
                _this.getData()
              } else {
                _this.$notification.error({message: res.message})
              }
            },
            onCancel() {
            }
          })
          break
      }
    },
  },
  created() {
    this.getData()
    this.getDeptList()
  }
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
