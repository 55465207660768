<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit"
           okText="提交">
    <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}"
                  :wrapper-col="{span:21}">

      <a-form-model-item label="所属部门" prop="deptId" :autoLink="false" ref="test">
        <a-tree-select
          v-model="rowData.deptId"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="depts"
          placeholder="所属部门"
          treeDefaultExpandAll>
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="(name">
        <a-input v-model="rowData.name" placeholder="名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="(flagEnable">
        <a-switch v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="(remark">
        <a-input v-model="rowData.remark" placeholder="备注"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {editSysStation, selectByIdSysStation, addSysStation} from '../api/SysStationApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        deptId: [
          {required: true, message: '请输入所属部门', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        flagEnable: [
          {required: true, message: '请输入是否启用', trigger: 'blur'}
        ],
      },
      depts: [],
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = row
      this.depts = []
      this.getDeptList()
      if(handle == 'add') {
        this.$set(this.rowData, 'flagEnable', true)
      }
      if(handle == 'edit') {
        selectByIdSysStation(row.id).then(res => {
          this.rowData = res.body
        })
      }
    },

    getDeptList() {
      this.axios.get('/api/base/system/department/all').then(res => {
        //this.deptList = res.body
        this.buildtree(res.body, this.depts, 0)
      })
    },


    // 对上级部门进行组装成树结构
    buildtree (list, arr, parentId) {
      list.forEach(item => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id + '',
            title: item.name,
            children: []
          }
          this.buildtree(list, child.children, item.id)
          arr.push(child)
        }
      })
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        const res = this.handle === 'add' ? await addSysStation(this.rowData) : await editSysStation(this.rowData)
        if (res.code === 200) {
          this.$notification.success({message: res.message})
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({message: res.message})
        }
      })
    }
  },
  created() {

  },

}
</script>

<style lang="less" scoped>

</style>
